var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-slider",
        _vm._b(
          {
            ref: "slider",
            model: {
              value: _vm.getSliderOptions.value,
              callback: function ($$v) {
                _vm.$set(_vm.getSliderOptions, "value", $$v)
              },
              expression: "getSliderOptions.value",
            },
          },
          "vue-slider",
          _vm.getSliderOptions,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }